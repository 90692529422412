<template>
  <v-container fluid class="pa-0 ma-0">
    <v-layout align-center justify-center v-if="loading" class="mt-2">
      <a-spin tip="Loading..."> </a-spin>
    </v-layout>
    <template v-else>
      <v-toolbar color="#f0f0f0">
        <v-toolbar-title>
          <strong>{{ kanriBango }}</strong>
        </v-toolbar-title>
        <v-btn dark rounded color="#1565c0" @click="CloseTab()" class="ml-5">
          <v-icon>mdi-arrow-left</v-icon>Back
        </v-btn>
      </v-toolbar>

      <v-card tile flat id="print">
        <hr />
        <v-row class="mt-n4 mb-n4">
          <v-col cols="6">
            <v-layout pt-4 pl-4>
              <p style="font-size: 20px; padding-top: 0; font-weight: bold;">
                件名:
              </p>
              <!-- <p
            style="font-size: 20px; padding-top: 0; font-weight: bold;"
            v-html="ruleBookData.document.RuleKenmei"
          ></p> -->
              <p
                style="font-size: 20px; padding-top: 0; font-weight: bold;"
                v-html="documentUpdateDetails.after ? previousTitle : kenmei"
              ></p>
            </v-layout>
          </v-col>
          <v-col cols="6">
            <v-layout pt-4 pl-4>
              <p style="font-size: 20px; padding-top: 0; font-weight: bold;">
                件名:
              </p>
              <p
                style="font-size: 20px; padding-top: 0; font-weight: bold;"
                v-html="documentUpdateDetails.after ? kenmei : ''"
              ></p>
            </v-layout>
          </v-col>
        </v-row>

        <v-toolbar dense flat>
          <v-row>
            <v-col class="d-flex">
              <h2 class="ml-2">BEFORE</h2>
              <v-spacer></v-spacer>
              <h3 class="mt-2">legend:</h3>
              <v-icon style="color:pink">mdi-checkbox-blank</v-icon>
              <h3 class="mt-2 mr-2">deleted</h3>
            </v-col>
            <v-col class="d-flex">
              <h2>AFTER</h2>
              <v-spacer></v-spacer>
              <h3 class="mt-2">legend:</h3>
              <v-icon style="color:lightgreen">mdi-checkbox-blank</v-icon>
              <h3 class="mt-2 mr-8">changes</h3>
            </v-col>
          </v-row>
        </v-toolbar>
        <hr />
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="6">
              <v-card
                max-width="1000"
                class="mt-1"
                v-if="documentUpdateDetails.before"
                style="overflow: auto;"
              >
                <!-- <v-flex
                  class="ma-2"
                  v-for="(data, index) in documentUpdateDetails.before"
                  :key="index"
                > -->
                <v-flex>
                  <div max-width="1000" class="rulebookBefore">
                    <div v-html="documentUpdateDetails.before"></div>
                    <!-- <div v-html="data"></div> -->
                  </div>
                </v-flex>
              </v-card>
              <v-card
                max-width="1000"
                class="mt-1"
                v-else
                style="overflow: auto;"
              >
                <v-flex
                  class="ma-2"
                  v-for="(data, index) in documentData"
                  :key="index"
                >
                  <!-- <v-flex> -->
                  <div max-width="1000" class="rulebookBefore">
                    <!-- <div v-html="ruleBookData.htmlContent"></div> -->
                    <div v-html="data"></div>
                  </div>
                </v-flex>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card
                width="100%"
                class="mt-1"
                v-if="documentUpdateDetails.after"
                style="overflow : auto;"
              >
                <!-- <v-flex
                  class="ma-2"
                  v-for="(data, index) in documentUpdateDetails.after"
                  :key="index"
                > -->
                <v-flex>
                  <div width="100%" class="rulebookAfter">
                    <div v-html="documentUpdateDetails.after"></div>
                    <!-- <div v-html="data"></div> -->
                  </div>
                </v-flex>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import axios from "axios";
// import Editor from "@tinymce/tinymce-vue";
// import tinymce from "tinymce/tinymce"
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
// import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";
// import theme from '!!raw-loader!tinymce/themes/silver/theme.min.js'
export default {
  title() {
    return `@RulebookComparing Comparing ${this.$route.params.controlNumber.substring(
      0,
      9
    )}`;
  },

  name: "newPage",
  props: ["search"],
  data() {
    return {
      tiny_init: {
        object_resizing: false,
        inline: true,
        readonly: 1,
        toolbar: false,
        menubar: false,
        visual: false,
        skin: false,
        content_css: false,
        content_style:
          [contentUiCss].join("\n") +
          "body { font-family:Times New Roman,Arial,sans-serif}",
        // content_style: [contentCss, contentUiCss].join('\n') +'body { font-family:Times New Roman,Arial,sans-serif}',
      },
      loading: false,
      thingToCopy: `sk-manual@ichijo.co.jp`,
      title_spacer: "　",
      documentUpdateDetails: {},
      comparedSekkei: [],
      ruleBookData: {},
      documentData: "",
      previousData: "",
      previousTitle: "",
      kanriBango: "",
      kenmei: "",
    };
  },
  created() {
    setTimeout(async () => {
      await this.createData();
      await this.getTitle();
    }, 600);
  },

  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
  },

  methods: {
    CloseTab() {
      // if (this.ruleBookData.deleted == true) {
      //   this.$router.push(`/deletedshiyo`);
      // }else{
      //   window.close();
      // }
      window.close();
    },
    async getTitle() {
      let url = `${this.api}rulebookHistory/titleCompare`;
      let obj = {
        newTitle: this.kenmei,
        oldTitle: this.previousTitle,
      };

      await axios.post(url, obj).then((res) => {
        if (res.data) {
          console.log(res.data);
          let arr = res.data;
          this.previousTitle = arr[0];
          this.kenmei = arr[1];
        }
      });
    },
    async createData() {
      let id = this.$route.params.controlNumber;
      let controlNum = this.$route.query.id2;
      let obj = {
        _id: id,
        newControlNumber: controlNum,
      };
      let url2 = `${this.api}get/history2`; // for getting data from history
      let url = `${this.api}get/this/${controlNum}`; // for getting data from rulebook finaldata
      let document1;
      let document2;
      await axios
        .get(url)
        .then((res) => {
          if (res.data) {
            console.log(res.data);
            this.ruleBookData = res.data;
            this.documentData = this.ruleBookData.document.content.map(
              (rec) => {
                let regex = new RegExp(
                  "http://hrdapps48:5010/uploads/image/froala/test/",
                  "g"
                );
                return rec.replace(
                  regex,
                  "https://s3.us-east-2.amazonaws.com/rulebook.files/Document_images/"
                );
              }
            );
            this.kanriBango = res.data.document.KanriBangou;
            this.kenmei = res.data.document.RuleKenmei;
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("Somethings Happen");
        });

      await axios.post(url2, obj).then((res) => {
        if (res.data) {
          console.log(res.data);
          if (res.data.previousData) {
            document1 = res.data.previousData;
            for (let i in document1) {
              var myLocal = new RegExp(
                "http://10.169.141.118:8080/document",
                "g"
              );
              document1[i] = document1[i]
                // .toString()
                // .replace(myLocal, 'https://rulebook.hrd-s.com/document')
                .replace(myLocal, "https://documentsearch.hrd-s.com/document"); // 2022-08-19

              var testBucket = new RegExp(
                "http://rulebook.testbucket.s3-website.us-east-2.amazonaws.com/document",
                "g"
              );
              document1[i] = document1[i]
                // .toString()
                // .replace(testBucket, 'https://rulebook.hrd-s.com/document')
                .replace(
                  testBucket,
                  "https://documentsearch.hrd-s.com/document"
                ); // 2022-08-19

              var hrdapps59 = new RegExp("http://hrdapps59:2929/newPage", "g");
              document1[i] = document1[i]
                // .toString()
                // .replace(hrdapps59, 'https://rulebook.hrd-s.com/document')
                .replace(
                  hrdapps59,
                  "https://documentsearch.hrd-s.com/document"
                ); // 2022-08-19

              var hrdapps592 = new RegExp(
                "http://hrdapps59:2929/document",
                "g"
              );
              document1[i] = document1[i]
                // .toString()
                // .replace(hrdapps592, 'https://rulebook.hrd-s.com/document')
                .replace(
                  hrdapps592,
                  "https://documentsearch.hrd-s.com/document"
                ); // 2022-08-19

              var link = new RegExp("http://10.168.64.159:6660/newPage", "g");
              document1[i] = document1[i]
                // .toString()
                // .replace(link, 'https://rulebook.hrd-s.com/document')
                .replace(link, "https://documentsearch.hrd-s.com/document"); // 2022-08-19

              var hrdapps48 = new RegExp("10.168.64.31", "g");
              document1[i] = document1[i]
                // .toString()
                .replace(hrdapps48, "hrdapps48");
              var hrd = new RegExp("hrdapps48.hrd-s.com", "g");
              document1[i] = document1[i]
                // .toString()
                .replace(hrd, "hrdapps48");
              document1[i] = `<div id="p${i +
                1}"></div><head>  <link href="https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css" rel="stylesheet"> </head> ${
                document1[i]
              }`;

              var icon = new RegExp("<i class", "g");
              if (document1[i].match(icon) == null) {
                var re2 = new RegExp("</a>", "gi");
                document1[i] = document1[i].replace(re2, function(str) {
                  let index = document1[i].indexOf(str);
                  if (
                    document1[i][index - 6].match(/#/g) ||
                    document1[i][index - 19].match(/#/g)
                  ) {
                    return str;
                  } else if (
                    document1[i][index - 8].match(
                      /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/gi
                    ) == null
                  )
                    return `<i class="small mdi mdi-file-document" style="color:red;background-color:white;"></i> ${str}  `;
                  else {
                    return `<i class="small mdi mdi-link" style="color:green;background-color:white;"></i> ${str}  `;
                  }
                });
              }
            }
            this.previousTitle = res.data.previousTitle;
          }
        }
      });

      if (document1) {
        document2 = this.documentData;
        let obj2 = {
          before: document1.join(),
          after: document2.join(),
        };
        console.log(obj2);
        let compareDataUrl = `${this.api}/shiyo/processHtmlContent`;
        // let compareDataUrl = `https://385rohlp8h.execute-api.us-east-2.amazonaws.com/prod/shiyo/processHtmlContentForArray`;
        axios.post(compareDataUrl, obj2).then((res) => {
          if (res.data) {
            console.log(res.data);
            let comparedData = res.data;

            var tempElementDel = document.createElement("div");
            var tempElementIns = document.createElement("div");
            tempElementDel.innerHTML = comparedData;
            tempElementIns.innerHTML = comparedData;

            // Find all <del> elements
            var delElements = tempElementDel.querySelectorAll("del");
            var insElements = tempElementIns.querySelectorAll("ins");

            // Remove the content inside <del> elements and the <del> tags themselves
            for (var i = 0; i < delElements.length; i++) {
              var delElement = delElements[i];
              delElement.parentNode.removeChild(delElement);
            }
            for (var o = 0; o < insElements.length; o++) {
              var insElement = insElements[o];
              insElement.parentNode.removeChild(insElement);
            }
            // Get the modified HTML string
            var modifiedHtmlStringDel = tempElementDel.innerHTML;
            var modifiedHtmlStringIns = tempElementIns.innerHTML;

            this.$set(
              this.documentUpdateDetails,
              "before",
              modifiedHtmlStringIns
            );
            this.$set(
              this.documentUpdateDetails,
              "after",
              modifiedHtmlStringDel
            );
            let regex = new RegExp(
              "http://hrdapps48:5010/uploads/image/froala/test/",
              "g"
            );
            this.documentUpdateDetails.before = this.documentUpdateDetails.before.replace(
              // /http:\\\\10.11.1.59:2929\\uploads\\/g,
              regex,
              "https://s3.us-east-2.amazonaws.com/rulebook.files/Document_images/"
            );
            this.documentUpdateDetails.after = this.documentUpdateDetails.after.replace(
              // /http:\\\\10.11.1.59:2929\\uploads\\/g,
              regex,
              "https://s3.us-east-2.amazonaws.com/rulebook.files/Document_images/"
            );
            console.log(this.documentUpdateDetails);
          }
        });
        // this.$set(this.documentUpdateDetails, "before", document1);
        // this.$set(this.documentUpdateDetails, "after", document2);
      }
    },

    roomPart(item) {
      if (Array.isArray(item)) {
        let string = " ";
        for (let text of item) {
          string += text + "、　";
        }
        // string = string.substring(string.length, 2)
        return string;
      } else {
        return item;
      }
    },
  },
};
</script>

<style scoped>
.viewed {
  background-color: #daf5b3;
}
@media only print {
  body {
    position: static;
  }

  div.v-toolbar * {
    display: none !important;
  }
  div.v-toolbar__content * {
    display: none !important;
  }
  nav.v-toolbar.elevation-0.theme--light {
    display: none !important;
  }

  div.ant-tabs-nav-scroll * {
    display: none !important;
  }
  div.v-card.v-card--flat.v-sheet.theme--light {
    margin: 0mm;
    padding: 0mm;
    overflow-y: visible !important;
    position: relative;
  }
}
</style>
